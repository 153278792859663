import axios from 'axios';
///let baseURL = "https://uno-service-dot-unodev.uc.r.appspot.com/";
//let baseURL = "https://uno-service-uat-dot-unodev.uc.r.appspot.com/";
//let baseURL = "https://uno-service-dot-uno-qa.uc.r.appspot.com/";
//let baseURL = "https://uno-service-test-dot-unodev.uc.r.appspot.com/";

//GKE Base URL
//let baseURL = 'http://35.226.181.242:3000/';

/* GKE Base Url Dev Env*/
// let baseURL = 'http://34.132.23.206:9090/';

/* GKE Base Url Test Env*/
//let baseURL = 'http://35.226.61.224:9090/';

/* SIAMAAS QA Env*/
let baseURL = 'http://104.154.110.174:3000/';

export const Post = (endPoints, reqContent, companyID, file) => {
  let headers = {
    'company-id': companyID,
  };
  if (file) {
    headers = {
      ...headers,
      'content-type': 'multipart/form-data',
    };
  }
  return axios.post(baseURL + endPoints, reqContent, {
    headers,
  });
};

export const Get = (endPoints, companyID) => {
  return axios.get(baseURL + endPoints, {
    headers: { 'company-id': companyID },
  });
};
let cancelToken;
export const GetWithCancleRequest = (endPoints, companyID) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();
  return axios
    .get(baseURL + endPoints, {
      cancelToken: cancelToken.token,
      headers: { 'company-id': companyID },
    })
    .then((res) => {
      cancelToken = undefined;
      return res;
    });
};
export const GetWithHeaders = (endPoints, headers) => {
  return axios.get(baseURL + endPoints, {
    headers: headers,
  });
};
export const GetWithQuery = (endPoints, compId, scheduleId) => {
  let query = JSON.stringify({ scheduleId: scheduleId });
  return axios.get(baseURL + endPoints, {
    headers: {
      'company-id': compId,
      query: query,
    },
  });
};

export const GetModules = (endPoints, config) => {
  return axios.get(baseURL + endPoints, {
    config,
  });
};

export const Put = (endPoints, reqContent, companyID) => {
  return axios.put(baseURL + endPoints, reqContent, {
    headers: { 'company-id': companyID },
  });
};

export const Patch = (endPoints, reqContent, companyID) => {
  return axios.patch(baseURL + endPoints, reqContent, {
    headers: { 'company-id': companyID },
  });
};

export const Delete = (endPoints, companyID, reqContent) => {
  return axios.delete(baseURL + endPoints, {
    data: reqContent,
    headers: { 'company-id': companyID },
  });
};

export const GetModulesWithoutBaseUrl = (endPoints, companyID) => {
  return axios.get(endPoints, {
    headers: { 'company-id': companyID },
  });
};

export const Upload = (endPoints, reqContent, companyID) => {
  return axios.post(baseURL + endPoints, reqContent, {
    headers: {
      'company-id': companyID,
      'content-type': 'multipart/form-data',
    },
  });
};

export const Download = (endPoints, companyID) => {
  return axios.get(endPoints, {
    headers: {},
  });
};
