import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAlaOBpEcHeM3ywbP2lxm3Cfxnkgw5yMnY',
  authDomain: 'siamaas-qa.firebaseapp.com',
  projectId: 'siamaas-qa',
  storageBucket: 'siamaas-qa.appspot.com',
  messagingSenderId: '599834232900',
  appId: '1:599834232900:web:35917af468478232709411',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
